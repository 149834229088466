import React, {Fragment} from 'react';
import {IconCall, IconMessage, IconPin, IconOpenLink} from '../components/icons/Icons'; 

const Contact = () => {
  return (
    <Fragment>
        <section className="contact" role="contentinfo">
            <div className="page-section">
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconMessage />
                        <h5>Chat with us</h5>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Our friendly team is here to help.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6><a href="mailto:support@helloaccessible.com">support@helloaccessible.com</a></h6>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconPin />
                        <h5>Visit us</h5>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>Come say hello at our office.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6>1575 W Georgia St, Vancouver, British Columbia, V6G 2V3, Canada.</h6>
                    </div>
                </div>
                <div className="contact-details-container">
                    <div className="contact-details">
                        <IconCall />
                        <h5>Call us</h5>
                    </div>
                    <div className="contact-details">
                        <div />
                        <p>We're happy to speak with you from Mon-Fri, 8am to 5pm.</p>
                    </div>
                    <div className="contact-details">
                        <div />
                        <h6>
                            <a href="https://calendly.com/helloaccessible" target="_blank" aria-label="Link opens in a new window" className="link-new-tab">+1 236-998-5336 <IconOpenLink /></a>
                        </h6>
                    </div>
                </div>
            </div>
            <div className="contact-right-column">
                <h2>The first step is the hardest</h2>
                <h3>We are here to help you rain or shine.</h3>
                <p>Coming soon...</p>
                <form>
                </form>
            </div>
        </section>
        <hr />
        <section className="faq page-section" role="contentinfo">
            <h2>Frequently Asked Questions</h2>
            <p>Coming soon...</p>
        </section>
    </Fragment>
  );
}

export default Contact;
