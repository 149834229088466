import React, {Fragment} from 'react';
import {LogoAnimated} from '../components/icons/Logos';

const ComingSoon = () => {
  return (
    <Fragment>
      <header id="coming-soon">
        <LogoAnimated />
        <h3><i>STAY TUNED</i></h3>
        <h2><i>This page is coming soon</i></h2>
      </header>
    </Fragment>
  );
}

export default ComingSoon;
