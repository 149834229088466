import React, {Fragment} from 'react';
import {ReactTicker} from '@guna81/react-ticker';
import {ServicesTab} from './home/Tabs';
import { IconUSA, IconWorld, IconCanada, IconEurope, IconOpenLink} from '../components/icons/Icons';
import {NavLink} from 'react-router-dom';

const HomeHeader = () => {
  return (
    <header id="content" role="main">
      <div>
        <h1>Realize your accessibility vision</h1>
          <br />
        <h3>Just as we care for all beings, an inclusive online presence reflects the commitment to your values and benefits all.</h3>
          <br />
        <p>Hello Accessible is a digital accessibility consulting firm in Vancouver, BC. Through offering expert audits, strategic services, and targeted trainings, we empower organizations to create accessible and intuitive user experiences.</p>
          <br /><br />
        <a href="https://calendly.com/helloaccessible/discovery" target="_blank" className="button link-new-tab" aria-label="Link opens in a new tab">
          Book a free consultation <IconOpenLink />
        </a>
      </div>
      <div>
        <video loop autoPlay muted playsinline aria-label="A man working on his laptop while petting his blind cat.">
          <source src="../../assets/stock-vid-2-edited.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </header>
  );
}

const data = [
  {
    id: 1,
    value:
      "In 2023, 8,227 lawsuits related to accessibility were filed in US federal court."
  },
  {
    id: 2,
    value:
      "90% of websites are inaccessible to people with disabilities who use assistive technologies."
  },
  {
    id: 3,
    value:
      "By 2060, the number of people 65 or older in USA is expected to double to 98 million."
  },
];

const HomeTicker = () => {
  return (
    <ReactTicker
      data={data.map((item) => item.value)}
      speed={40}
      keyName="_id"
      tickerClassName="news-ticker"
    />
  );
}

const HomeServices = () => {
  return (
    <section className="page-section" id="services" role="contentinfo">
      <div className="page-section-heading">
        <div>
          <h2>Unlock a new competitive edge</h2>
          <h3>Tap into a growing and underserved market, work with our trusted experts</h3>
        </div>
      </div>
      <ServicesTab />
    </section>
  );
}

const HomeCTA = () => {
  return (
    <section id="cta" role="contentinfo">
      <div>
        <video loop autoPlay muted playsinline aria-label="A hand using a braille keyboard.">
          <source src="../../assets/stock-vid-3-edited.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div>
        <h3>Not sure what you need?</h3>
        <p>Speak to our expert today by booking a free consultation call. In our 30 minute consultation, we will learn about your business's existing accessibility challenges and explore our path forward together.</p>
        <br />
        <a href="https://calendly.com/helloaccessible/discovery" target="_blank" className="button link-new-tab" aria-label="Link opens in a new tab">
          Book a free consultation <IconOpenLink />
        </a>
      </div>
    </section>
  );
}

const HomeLaws = () => {
  return (
    <section id="laws-marquee" className="page-section page-section-content" role="contentinfo">
        <div className="page-section-heading">
          <div>
            <h2>Stay ahead of evolving laws</h2>
            <h3>Hello Accessible keeps up with the latest regulations for your peace of mind</h3>
            <br />
            <p>
              In 2023, 8,000+ lawsuits were filed in federal court under Title III of the ADA. Navigating the complex landscape of accessibility laws and regulations can be daunting, but it doesn't have to be. At Hello Accessible, we stay ahead of the curve by constantly monitoring and interpreting the latest legal requirements, ensuring your business remains compliant and inclusive. Our dedicated team of experts provides tailored guidance and practical solutions, giving you the peace of mind to focus on what you do best. With our support, you can confidently meet and exceed accessibility standards, fostering an environment where everyone feels welcome and valued.
            </p>
          </div>
        </div>
        <article>
          <div class="grid">
            <NavLink to="/services">
              <IconWorld /> WCAG
            </NavLink>
            <NavLink to="/services">
              <IconUSA /> ADA
            </NavLink>
            <NavLink to="/services">
              <IconUSA /> Section 508
            </NavLink>
            <NavLink to="/services">
              <IconCanada /> ACA
            </NavLink>
            <NavLink to="/services">
              <IconEurope /> EAA
            </NavLink>
            <NavLink to="/services">
              <IconEurope /> EN 301 549
            </NavLink>
            <NavLink to="/services">
              <IconCanada /> AODA
            </NavLink>
            <NavLink to="/services">
              & More
            </NavLink>
          </div>
        </article>
    </section>
  );
}
 
const Home = () => {
  return (
    <Fragment>
      <HomeHeader />
      <HomeTicker />
      <hr />
      <HomeLaws />
      <hr />
      <HomeServices />
      {/* <section className="final-section">
        
      </section> */}
      <hr />
      <HomeCTA />
    </Fragment>
  );
}

export default Home;
